import { createStore, combineReducers } from 'redux';

// Define action types
const SHOW_ADD_TICKET_POPUP = 'SHOW_ADD_TICKET_POPUP';
const HIDE_ADD_TICKET_POPUP = 'HIDE_ADD_TICKET_POPUP';
const SHOW_TICKET_DETAILS_POPUP = 'SHOW_TICKET_DETAILS_POPUP';
const HIDE_TICKET_DETAILS_POPUP = 'HIDE_TICKET_DETAILS_POPUP';
const SET_USER_DATA = 'SET_USER_DATA';
const SET_TICKETS = 'SET_TICKETS';
const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
const IS_LOADING = 'IS_LOADING'; // Use a more descriptive name like 'IS_LOADING'

// Define action creators
export const showAddTicketPopup = (ticketType) => ({ type: SHOW_ADD_TICKET_POPUP, payload: ticketType });
export const hideAddTicketPopup = () => ({ type: HIDE_ADD_TICKET_POPUP });
export const showTicketDetailsPopup = (ticketDetails) => ({
  type: SHOW_TICKET_DETAILS_POPUP,
  payload: ticketDetails,
});
export const hideTicketDetailsPopup = () => ({ type: HIDE_TICKET_DETAILS_POPUP });
export const setUserData = (userData) => ({ type: SET_USER_DATA, payload: userData });
export const setTickets = (tickets) => ({ type: SET_TICKETS, payload: tickets });
export const setPrice = (priceData) => ({ type: SET_SELECTED_PLAN, payload: priceData });
export const setLoading = (isLoading) => ({
  type: IS_LOADING,
  payload: isLoading,
});

// Define initial state
const initialState = {
  showAddTicketPopup: false,
  showTicketDetailsPopup: false,
  ticketDetails: {},
  userData: {},
  tickets: [],
  priceData: {},
  loading: false,
};

// Define reducer
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ADD_TICKET_POPUP:
      return { ...state, showAddTicketPopup: true, ticketType: action.payload };
    case HIDE_ADD_TICKET_POPUP:
      return { ...state, showAddTicketPopup: false };
    case SHOW_TICKET_DETAILS_POPUP:
      return { ...state, showTicketDetailsPopup: true, ticketDetails: action.payload };
    case HIDE_TICKET_DETAILS_POPUP:
      return { ...state, showTicketDetailsPopup: false, ticketDetails: {} };
    case SET_USER_DATA:
      return { ...state, userData: action.payload };
    case SET_TICKETS:
      return { ...state, tickets: action.payload };
    case SET_SELECTED_PLAN:
      return { ...state, priceData: action.payload };
    case IS_LOADING:
      return { ...state, loading: action.payload }; // Updated to 'loading'
    default:
      return state;
  }
};

// Create store
const store = createStore(combineReducers({ root: rootReducer }));

export default store;
