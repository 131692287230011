  import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Pagination,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Check, Search } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
// import PrintIcon from "@mui/icons-material/Print";
// import ReplayIcon from '@mui/icons-material/Replay';
import UserPng from "../Assets/user.png";
import { apiUrl } from "../Pages/Config";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import tagImg from "../Assets/tag-img.jpg";
import cameraImg from "../Assets/camera.png";
import Chip from "@mui/joy/Chip";
import { ListItemDecorator } from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import CardActions from "@mui/joy/CardActions";
import PersonIconPng from "../Assets/user.png";
import tickPng from "../Assets/tick.png";
import nameIcon from "../Assets/nameIcon.svg";
import emailIcon from "../Assets/emailIcon.svg";
import numberIcon from "../Assets/numberIcon.svg";
import companyIcon from "../Assets/companyIcon.svg";
import userCountIcon from "../Assets/userCountIcon.svg";
import printIcon from "../Assets/printIcon.svg";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const getData = async (
  setProfileData,
  setproductData,
  activeProductData,
  setActiveProductData,
  setInvoiceData,
  setPlanDetailsArray,
  setRenewActive
) => {
  const logId = localStorage.getItem("Id");
  const accessToken = localStorage.getItem("accessToken");
  // console.log("Hai i am running from function...",logId);
  if (logId && accessToken) {
    try {
      const response = await fetch(apiUrl + "getSspUserDetails/" + logId + '?productname=FrothTestops', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const myAccountData = await response.json();
      // console.log('res--',myAccountData);
      const productActive = Object.values(myAccountData.productDetails)
        .filter((productDetails) => productDetails.status === "Active")
        .map((productDetails, index) => ({
          productDetails,
        }));
    
      setProfileData(myAccountData.customerDetails);
     
      if(productActive.length===0){
        // console.log('productActive-empty-',myAccountData.productDetails.product_1);
        setActiveProductData(myAccountData.productDetails.product_1);

        const planDetailsArray = myAccountData.productDetails.product_1.plan_details
        .split("|")
        .map((detail) => detail.trim());
      setPlanDetailsArray(planDetailsArray);

      const today = new Date();
      const domainExpiry = new Date(myAccountData.productDetails.product_1.due_date);
      const diffTime = domainExpiry.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log('domainExpiry--',domainExpiry);
      if (diffDays <= 7) {
        setRenewActive(false);
      }

      } else {
        setActiveProductData(productActive[0].productDetails);
        const planDetailsArray = productActive[0].productDetails.plan_details
        .split("|")
        .map((detail) => detail.trim());
      setPlanDetailsArray(planDetailsArray);
      const today = new Date();
      const domainExpiry = new Date(productActive[0].productDetails.due_date);
      const diffTime = domainExpiry.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log('domainExpiry--',domainExpiry);
      if (diffDays <= 7) {
        setRenewActive(false);
      }
      }
      
      
      
      const productData = Object.values(myAccountData.productDetails)
        .filter((productDetails) => productDetails.status != "Active")
        .map((productDetails, index) => ({
          s_no: index + 1,
          plan: productDetails.plantype,
          s_date: formatDate(productDetails.purchase_date),
          e_date: formatDate(productDetails.due_date),
          users: productDetails.number_of_users,
          status: productDetails.status
        }));
      
      
      
      if(productData.length===0){
        const productDataInact = Object.values(myAccountData.productDetails)
        .filter((productDetails) => productDetails.status === "Inactive")
        .map((productDetails, index) => ({
          s_no: index + 1,
          plan: productDetails.plantype,
          s_date: formatDate(productDetails.purchase_date),
          e_date: formatDate(productDetails.due_date),
          users: productDetails.number_of_users,
          status: productDetails.status
        }));
        setproductData(productDataInact);
      } else {
        setproductData(productData);
      }


      const invoiceDataArr = Object.values(
        myAccountData.customerInvoiceDetails
      ).map((invoice, index) => ({
        S_No: index + 1,
        date: formatDate(invoice.invoiceDate),
        Invoice: invoice.invoiceNumber, // You can customize the invoice number format
        plan: invoice.planType,
        price: invoice.totalAmt,
        invoicePdfUrl: invoice.invoicePdfUrl,
      }));
      setInvoiceData(invoiceDataArr);
      // console.log('activeProductData--',activeProductData);

    } catch (error) {
      console.log("error", error);
    }
  }
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Myaccount() {
  const [profileData, setProfileData] = useState([null]);
  const [activeProductData, setActiveProductData] = useState([null]);
  const [productData, setproductData] = useState([null]);
  const [invoiceData, setInvoiceData] = useState();
  const [planData, setPlanData] = useState();
  const [planDetailsArray, setPlanDetailsArray] = useState([]);
  const [renewActive, setRenewActive] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const isadmin = localStorage.getItem("isadmin");
  if(!isadmin){
    navigate("/Self-Service");
  }
  
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    getData(
      setProfileData,
      setproductData,
      activeProductData,
      setActiveProductData,
      setInvoiceData,
      setPlanDetailsArray,
      setRenewActive
    );
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renewNow = () => {
  //  console.log("tester...");
    // navigate(invoiceData);
    window.open("/Pricing", "_blank");
  };

  // console.log('invoiceData--', invoiceData);
  // console.log('activeProductData-arr-',activeProductData);
  const columns = [
    {
      name: "S_No",
      label: "S.NO",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "Invoice",
      label: "Invoice",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "plan",
      label: "Plan",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "date",
      label: "Invoice Date",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = invoiceData[tableMeta.rowIndex];
          const invoicePdfUrl = rowData.invoicePdfUrl;

          return (
            <>
           
           
              <Button
                title="Print Invoice"
                sx={{ fontWeight: 500 }}
                variant="outline"
                size="small"
                onClick={() => window.open(invoicePdfUrl, "_blank")}
              >
                <img src={printIcon} width={20} />
              </Button>
            </>
          );
        },
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
  ];

  const addcolumns = [
    {
      name: "s_no",
      label: "S.NO",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "plan",
      label: "Plan",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "s_date",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "e_date",
      label: "End Date",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "users",
      label: "Users",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 5,
    download: true,
    print: true,
  };

  // pagination
  const totalPages = Math.ceil(productData.length / pageSize);

  useEffect(() => {
    paginateData();
  }, [productData, currentPage, pageSize]);

  const paginateData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const sortedTickets = productData.sort(
      (a, b) => b.case_number - a.case_number
    );
    return sortedTickets.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, 2);

      if (currentPage > 5 && currentPage < totalPages - 3) {
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        pageNumbers.push("...");
      } else if (currentPage <= 3) {
        pageNumbers.push(3, 4);
        pageNumbers.push("...");
      } else {
        pageNumbers.push("...");
        pageNumbers.push(totalPages - 3, totalPages - 2);
      }
      pageNumbers.push(totalPages - 1, totalPages);
    }

    return (
      <nav>
        <ul className="pagination justify-content-center mt-3 mt-md-0">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &laquo;
            </button>
          </li>
          {pageNumbers.map((number, index) => (
            <li
              key={index}
              className={`page-item ${number === currentPage ? "active" : ""}`}
            >
              {number === "..." ? (
                <span className="page-link">{number}</span>
              ) : (
                <button
                  className="page-link"
                  onClick={() => handlePageChange(number)}
                >
                  {number}
                </button>
              )}
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    );
  };
// console.log('totalPages--',totalPages);

  
  return (
    <>
      <Grid className="profile-page-container" container>
        <Grid container sx={{ m: 1 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className="breadcrumb-home" underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Link className="breadcrumb-home" underline="hover" color="inherit" to="/Self-Service">
              Self Service
            </Link>
            <Typography className="breadcrumb-active" sx={{ color: "text.primary" }}>My Account</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid className="myaccount-container" container sx={{ m: 1 }}>

        <Typography component="div" className="profile-card-image col-lg-2 col-xl-2 col-md-12 col-12 ">
        <Avatar
                  src={UserPng}
                  alt="Profile Image"
                  className="profile-img"
                />
                <img className="profile-upload-img" src={cameraImg} />
            </Typography>
            <Typography component="div" className="col-lg-5 col-xl-5 col-md-12 col-12 personal-info" > 

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className="col-6 personal-info-line" > 
                <img src={nameIcon} width={20} /> Name  
                </Typography>
                <Typography className="col-6 personal-info-ans"> {profileData.name} </Typography>
                </Typography>

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className ="col-6 personal-info-line" > 
                <img src={emailIcon} width={20} /> E-Mail  
              </Typography>
              <Typography className="col-6 personal-info-ans"> {profileData.email} </Typography>
              </Typography>

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className ="col-6 personal-info-line" > 
                <img src={numberIcon} width={20} /> Number  
              </Typography>
              <Typography className="col-6 personal-info-ans"> {profileData.phone_office} </Typography>
              </Typography>

              </Typography>

              <Typography component="div" className="col-lg-5 col-xl-5 col-md-12 col-12 personal-info" > 

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className="col-6 personal-info-line" > 
                <img src={companyIcon} width={20} /> Company Name  
                </Typography>
                <Typography className="col-6 personal-info-ans"> {profileData.organization_name} </Typography>
                </Typography>

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className ="col-6 personal-info-line" > 
                <img src={userCountIcon} width={20} /> User  
              </Typography>
              <Typography className="col-6 personal-info-ans"> { activeProductData && activeProductData.number_of_users} </Typography>
              </Typography>



              </Typography>

        </Grid>

        <Grid container sx={{ m: 1 }}>
        <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="prof-tab-heading plan-tab"
                    label="Plan Details"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="prof-tab-heading plan-tab"
                    label="Billing Details"
                    {...a11yProps(1)}
                  />

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <Typography component="div" className="plan-details" >
                    <Typography component="div" className="row" >
                    <Typography component="div" className="col-lg-6 col-xl-6 col-12" >
                     <h4>Features</h4>
                    </Typography>
                    <Typography component="div" className="col-lg-6 col-xl-6 col-12" >
                    <Typography component="div" className="row" >
                    <Typography component="div" className="col-lg-6 col-xl-6 col-12 current-package" >
                       <h6> Current Package  </h6> 
                       <h6 className="active-package"> { activeProductData && activeProductData.plantype} </h6>  
                     </Typography>
                    <Typography component="div" className="col-lg-6 col-xl-6 col-12 expiry-package" > 
                      <h6> Expiry  </h6>  
                      <h6 className="active-package"> {activeProductData && formatDate(activeProductData.due_date)} </h6>  
                      </Typography>
                    </Typography>
                      </Typography>
                    </Typography>

                    <Typography component="div" className="row plan-feature" >
                    {planDetailsArray.map((detail, index) => (
                    <Typography component="div" className="col-lg-4 col-xl-4 col-md-6 col-12 feature" >
                        <Typography component="img" src={tickPng} ></Typography>
                        <Typography>{detail}</Typography>
                    </Typography>
                    ))}
                   
                    </Typography>

                    <Typography component="div" className="plan-details-footer" >
                    <Button disabled={renewActive} onClick={() => renewNow()} variant="contained" className="renew-btn" >Renew Now</Button>
                    </Typography>

                    </Typography>
                    <Card
                    sx={{ mt: "10px" }}
                    className="mt"
                    size="lg"
                    variant="outlined"
                  >
     
                     <div class="table-responsive">
                    <table className="table text-center">
                    <thead>
                      <tr>
                        <th scope="col">S NO</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Users</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    { paginateData() && paginateData().length > 1 ? (
                    paginateData().map((product, index) => (
                    <tr>
                      <td>{ product.s_no }</td>
                      <td>{ product.plan }</td>
                      <td>{ product.s_date }</td>
                      <td>{ product.e_date }</td>
                      <td>{ product.users }</td>
                      <td>{ product.status }</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No products available</td>
                  </tr>
                )}
              </tbody>
                    </table>
                    </div>
                    <div className="Pagination">
        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3 mb-3 text-center"
          style={{ textWrap: "nowrap" }}
        >
          <span>Items per page</span>
          <select
            className="form-select table-item-select"
            value={pageSize}
            onChange={handlePageSizeChange}
            style={{ width: "auto" }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={1}
        />
      </div>
                  </Card>
                  </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                  <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                  }}
                >
                  <Card size="lg" variant="outlined">
                    <MUIDataTable
                      title={"Billing Logs"}
                      data={invoiceData}
                      columns={columns}
                      options={options}
                    />
                  </Card>
                </Box>
                  </CustomTabPanel>

          </Box>
          {/* </Box> */}
        </Grid>
       
      <Typography component="div" className="row " >




        {/* <Grid item xs={6} md={3} lg={3} xl={3} sm={4}>
          <Card
            className="profile-card"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography component="div" className="profile-card-image">
              <Typography component="div" className="prof-img-bg">
                <Avatar
                  src={UserPng}
                  alt="Profile Image"
                  sx={{
                    width: 100,
                    height: 100,
                    m: 3,
                    background: "#eeeeee",
                    zIndex: 1,
                  }}
                />
              </Typography>
              <Typography component="div" className="prof-camera-bg">
                <img className="prof-camera" src={cameraImg} width="20px" />
              </Typography>
            </Typography>
            <Typography sx={{ mt: "-5px", fontWeight: "bold" }} variant="h6">
              {profileData.name}
            </Typography>
            <Typography variant="p">{profileData.organization_name}</Typography>

            <Divider sx={{ width: "100%" }} />
            <Typography sx={{ fontWeight: 500, mt: 1, mb: 1 }} variant="p">
              {profileData.email}
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <Typography sx={{ fontWeight: 500, mt: 1, mb: 1 }} variant="p">
              {profileData.phone_office}
            </Typography>
            <Typography sx={{ fontWeight: 500, mt: 1, mb: 1 }} variant="p">
              {profileData.product_name}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={9} lg={9} xl={9} sm={4}>
          <Card
            className="profile-progress-card"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="prof-tab-heading"
                    label="My Account"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="prof-tab-heading"
                    label="Plan Details"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="prof-tab-heading"
                    label="Billing Details"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box
                  component="form"
                  sx={{ "& > :not(style)": { m: 1, width: "40ch" } }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-name"
                    label="Name"
                    variant="outlined"
                    value={profileData.name}
                    InputLabelProps={
                      profileData.name
                        ? {
                            shrink: true,
                          }
                        : ""
                    }
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />

                  <TextField
                    id="outlined-email"
                    label="Email"
                    variant="outlined"
                    value={profileData.email}
                    InputLabelProps={
                      profileData.email
                        ? {
                            shrink: true,
                          }
                        : ""
                    }
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />
                  <TextField
                    id="outlined-phone"
                    label="Phone"
                    variant="outlined"
                    value={profileData.phone_office}
                    InputLabelProps={
                      profileData.phone_office
                        ? {
                            shrink: true,
                          }
                        : ""
                    }
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    value={profileData.organization_name}
                    InputLabelProps={
                      profileData.organization_name
                        ? {
                            shrink: true,
                          }
                        : ""
                    }
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Total Users"
                    variant="outlined"
                    value={activeProductData.number_of_users}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />

                  <Box
                    container
                    sx={{
                      "& > :not(style)": {
                        m: 1,
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                      },
                    }}
                  >
                   
                  </Box>
                </Box>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                  }}
                >
                  <Card size="lg" variant="outlined">
                    <Typography
                      component="div"
                      sx={{ display: "flex", gap: "531px" }}
                    >
                      <Chip
                        className="plan-card-title"
                        size="sm"
                        variant="outlined"
                        color="neutral"
                      >
                        Current Package : {activeProductData.plantype}
                      </Chip>
                      <Chip
                        className="plan-card-title"
                        size="sm"
                        variant="outlined"
                        color="neutral"
                      >
                        Expiry {formatDate(activeProductData.due_date)}
                      </Chip>
                    </Typography>
                    <Typography
                      sx={{ ml: "15px", mt: "2px", mb: "2px" }}
                      level="h2"
                    >
                      {" "}
                      Features{" "}
                    </Typography>
                    <Divider inset="no ne" />
                    <Box container>
                      <Grid container sx={{ display: "flex", margin: "5px" }}>
                        {planDetailsArray.map((detail, index) => (
                          <Grid
                            key={index}
                            item
                            xs={6}
                            md={6}
                            lg={4}
                            xl={4}
                            sm={6}
                          >
                            <ListItem sx={{ lineHeight: "2" }}>
                              <ListItemDecorator>
                                <Check size="small" />
                              </ListItemDecorator>
                              {detail}
                            </ListItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Divider inset="none" />
                    <CardActions>
                      <Typography
                        container
                        component="div"
                        sx={{
                          display: "flex",
                          gap: "631px",
                          margin: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography component="div">
                          <Typography variant="p" sx={{ fontWeight: 900 }}>
                            ${" "}
                            {activeProductData.monthly_cost > 0
                              ? activeProductData.monthly_cost + " / month"
                              : activeProductData.yearly_cost + " / year"}
                          </Typography>
                        </Typography>

                        <Typography
                          component="div"
                          className="plan-buttons"
                          sx={{ display: "flex", gap: "8px" }}
                        >
                          <Button
                            disabled={renewActive}
                            onClick={renewNow}
                            title="Renew"
                            sx={{ backgroundColor: "#ff0000", fontWeight: 500 }}
                            variant="contained"
                            size="small"
                          >
                            Renew now {renewActive}
                          </Button>
                        </Typography>
                      </Typography>
                    </CardActions>
                  </Card>

                  <Card
                    sx={{ mt: "10px" }}
                    className="mt"
                    size="lg"
                    variant="outlined"
                  >
                    <MUIDataTable
                      title={"Plan Details"}
                      data={productData}
                      columns={addcolumns}
                      options={options}
                    />
                  </Card>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                  }}
                >
                  <Card size="lg" variant="outlined">
                    <MUIDataTable
                      title={"Billing Logs"}
                      data={invoiceData}
                      columns={columns}
                      options={options}
                    />
                  </Card>
                </Box>
              </CustomTabPanel>
            </Box>
          </Card>
        </Grid> */}
        </Typography>
      </Grid>
    </>
  );
}

export default Myaccount;
