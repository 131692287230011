import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showAddTicketPopup,
  showTicketDetailsPopup,
  hideTicketDetailsPopup,
  setTickets,
  hideAddTicketPopup,
} from "../../Pages/Store";
import AddTicket from "./AddTicket";
import TicketDetails from "./TicketDetails";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl, statusColors } from "../../Pages/Config";
import "../../Styles/SelfService.css";
// import { FiLogOut } from "react-icons/fi";
import { toast } from "react-toastify";
import useIdleTimer from "../../Pages/UseIdleTimer";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UserPng from "../../Assets/user-icon.png";

const SelfService = () => {
  const navigate = useNavigate();
  const [ticketNumber, setTicketNumber] = useState("");
  const [sortValue, setSortValue] = useState("All");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const tickets = useSelector((state) => state.root.tickets);
  const dispatch = useDispatch();
  const {
    showAddTicketPopup: showAddTicket,
    showTicketDetailsPopup: showTicketDetails,
    ticketDetails,
  } = useSelector((state) => state.root);
  const login = localStorage.getItem("Login");
  const isadmin = localStorage.getItem("isadmin");
  // console.log("login-", login);

  const handleLogout = () => {
    // console.log('logoutTrigger');
    localStorage.removeItem("Login");
    localStorage.removeItem("isadmin");
    navigate("/login");
    // localStorage.removeItem("Login");
  };

  const handleMyAccount = () => {
    navigate("/my-account");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  //string avatar image

  const userName = localStorage.getItem("name") || "User";
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useIdleTimer(handleLogout, 900000);

  useEffect(() => {
    const login = localStorage.getItem("Login");
    // console.log('login-',login);

    if (!login) {
      handleLogout();
    }
  }, [login]);

  useEffect(() => {
    // Define the base URL and website token
    const userName = localStorage.getItem("name");
    const userEmail = localStorage.getItem("email");
    const BASE_URL = "https://chat.frothdesk.com/";

    // Create the script element
    const script = document.createElement("script");
    script.src = BASE_URL + "/packs/js/sdk.js";
    script.async = true;
    script.defer = true;

    // Append the script to the document
    document.body.appendChild(script);

    // Initialize the chat widget after script is loaded
    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: "kPdqeTAbZWz2kg4dDMiUrwoj",
        baseUrl: BASE_URL,
      });
    };

    window.addEventListener("chatwoot:ready", function () {
      if (window.$chatwoot) {
        window.$chatwoot.setUser(userName, {
          email: userEmail,
          name: userName,
        });
      }
    });

    // Cleanup: Remove the script if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (tickets.length === 0) {
      handleData();
    }
    dispatch(hideTicketDetailsPopup());
    dispatch(hideAddTicketPopup());
    getKnowledgeBase();
  }, []);

  const [dataBase, setDataBase] = useState([]);
const [isOpen, setIsOpen] = useState(false);
 
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
 
const getKnowledgeBase = async () => {
    try {
      const response = await fetch(
        "https://rd.frothcrm.com/bookstack/public/api/books/3",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Token qOfphsoywp0oV3c3PGBycCBvceopq0cI:Yz8AhgQWyjXPI4FxPfxtXnb21wSkfesG",
          },
        }
      );
 
      if (!response.ok) {
        toast.warn("Something went wrong!", {
          position: "top-right",
          autoClose: 2000,
        });
        throw new Error(`Error: ${response.status}`);
      }
 
      const data = await response.json();
      setDataBase(data?.contents);
    } catch (error) {
      console.error("Failed to fetch books:", error);
    }
  };
 
const openInNewTab = (content) => {
    const newWindow = window.open();
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.document.close();
  };
 
  const showContent = async (name, id) => {
    try {
      const response = await fetch(
        `https://rd.frothcrm.com/bookstack/public/api/pages/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Token qOfphsoywp0oV3c3PGBycCBvceopq0cI:Yz8AhgQWyjXPI4FxPfxtXnb21wSkfesG",
          },
        }
      );
 
      // if (!response.ok) {
      //   toast.warn("Something went wrong!", {
      //     position: "top-right",
      //     autoClose: 2000,
      //   });
      //   throw new Error(`Error: ${response.status}`);
      // }
      const dataBook = await response.json();
      const content = `
      <html>
      <head>
      <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22
http://www.w3.org/2000/svg%22
viewBox=%220 0 36 36%22><text y=%2232%22 font-size=%2232%22>🌍</text></svg>" />
        <title>${name}</title>
      </head>
      <body>
              ${dataBook?.html}
      </body>
      </html>
    `;
      openInNewTab(content);
    } catch (error) {
      toast.warn("Failed to fetch books!", {
        position: "top-right",
        autoClose: 2000,
      });
      console.error("Failed to fetch books:", error);
    }
  };

  const handleData = async () => {
    setLoading(true);
    try {
      const ticketResponse = await fetch(
        `${apiUrl}fetchUserTickets/${localStorage.getItem("Id")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      const fetchResponse = await ticketResponse.json();
      if (
        fetchResponse.message &&
        fetchResponse.message.includes("Account tickets not found")
      ) {
        dispatch(setTickets([]));
        setLoading(false);
      } else {
        const data = fetchResponse.filter(
          (project) =>
            project.product_name &&
            project.product_name.toLowerCase() === "frothtestops"
        );
        dispatch(setTickets(data));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      dispatch(setTickets([]));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSortChange({ target: { value: sortValue } });
  }, [tickets]);

  useEffect(() => {
    paginateTickets();
  }, [filteredTickets, currentPage, pageSize]);

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSortValue(selectedValue);

    if (tickets.length === 0) {
      setFilteredTickets([]);
    } else if (selectedValue === "All") {
      setFilteredTickets(tickets);
    } else {
      const filteredData = tickets.filter((ticket) => {
        const statusParts = ticket.status.split("_");
        const mainStatus =
          statusParts.length > 1 ? statusParts[1] : ticket.status;
        return mainStatus === selectedValue;
      });
      setFilteredTickets(filteredData);
    }
    setCurrentPage(1);
  };
  
  const handleTicketType = (event) => {
    const ticketType = event.target.value; // Capture the selected ticket type
    console.log("Selected Ticket Type:", ticketType);
    dispatch(showAddTicketPopup(ticketType)); // Pass it to the action
  };

  const searchTicket = () => {
    if (ticketNumber.trim()) {
      const searchTicket = tickets.find(
        (ticket) => String(ticket.case_number) === ticketNumber.trim()
      );

      if (searchTicket) {
        dispatch(showTicketDetailsPopup(searchTicket));
      } else {
        toast.warn("Ticket not found", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      setTicketNumber("");
    } else {
      toast.warn("Enter case number to search", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const totalTickets = tickets.length;
  let openTickets = tickets.filter((ticket) =>
    ticket.status.includes("Open")
  ).length;

  const paginateTickets = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const sortedTickets = filteredTickets.sort(
      (a, b) => b.case_number - a.case_number
    );
    return sortedTickets.slice(startIndex, endIndex);
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      searchTicket();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredTickets.length / pageSize);

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, 2);

      if (currentPage > 5 && currentPage < totalPages - 3) {
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        pageNumbers.push("...");
      } else if (currentPage <= 3) {
        pageNumbers.push(3, 4);
        pageNumbers.push("...");
      } else {
        pageNumbers.push("...");
        pageNumbers.push(totalPages - 3, totalPages - 2);
      }
      pageNumbers.push(totalPages - 1, totalPages);
    }



    return (
      <nav>
        <ul className="pagination justify-content-center mt-3 mt-md-0">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &laquo;
            </button>
          </li>
          {pageNumbers.map((number, index) => (
            <li
              key={index}
              className={`page-item ${number === currentPage ? "active" : ""}`}
            >
              {number === "..." ? (
                <span className="page-link">{number}</span>
              ) : (
                <button
                  className="page-link"
                  onClick={() => handlePageChange(number)}
                >
                  {number}
                </button>
              )}
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="container-fluid" style={{ padding: "35px 18px 35px 18px" }}>
      <div className="row breadcrumb-contain">
        <div className="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-8 breadcrumb-nav">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="breadcrumb-home"
              underline="hover"
              color="inherit"
              to="/"
            >
              Home
            </Link>

            <Typography
              className="breadcrumb-active"
              sx={{ color: "text.primary" }}
            >
              Self Service
            </Typography>
          </Breadcrumbs>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-4 col-4 nav-profile ">
          <Avatar
            src={UserPng}
            className="profile-avatar dropdown-toggle"
            onClick={handleMenu}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <ListItemIcon>
                {" "}
                <PersonIcon />{" "}
                <ListItemText className="menu-item-text">
                  {" "}
                  {localStorage.getItem("name")}
                </ListItemText>{" "}
              </ListItemIcon>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                {" "}
                <EmailIcon />{" "}
                <ListItemText className="menu-item-text">
                  {" "}
                  {localStorage.getItem("email")}
                </ListItemText>{" "}
              </ListItemIcon>
            </MenuItem>
            {isadmin == 1 ? (
              <MenuItem onClick={handleMyAccount}>
                <ListItemIcon>
                  {" "}
                  <ManageAccountsIcon />{" "}
                  <ListItemText className="menu-item-text">
                    {" "}
                    My Account
                  </ListItemText>{" "}
                </ListItemIcon>
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                {" "}
                <LogoutIcon />{" "}
                <ListItemText className="menu-item-text">
                  {" "}
                  Logout
                </ListItemText>{" "}
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="container-fluid mb-3" style={{ padding: "0" }}>
        <div className="row ticket-table-nav">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ticket-count">
            <div className="total-ticket">
              <b>Total Tickets: {totalTickets}</b>
            </div>
            <div className="open-ticket">
              <b>Open Tickets: {openTickets}</b>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 ticket-buttons">
            <div className="search-input">
              <input
                type="text"
                className="form-control mb-2 mb-md-0 me-md-0"
                id="ticketNumber"
                placeholder="Search Case Number"
                value={ticketNumber}
                onKeyDown={handleSearch}
                onChange={(e) => setTicketNumber(e.target.value)}
              />
              <button type="button" onClick={searchTicket}>
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
           
            <div className="new-ticket">
            <select
                className="form-select"
                id="sortSelect"
                onChange={handleTicketType}
                data-toggle="tooltip"
                data-placement="top"
                title="Filter by Status"
                value={sortValue}
              >
                <option value="All">Select Ticket Type</option>
                <option value="Incident">Incident</option>
                <option value="Service Request">Service Request</option>
                <option value="Change Request">Change Request</option>
              </select>

              {/* <button
                type="button"
                className="btn btn-light mb-2 mb-md-0 me-2 add-btn"
                onClick={() => dispatch(showAddTicketPopup())}
                style={{ width: "8.9rem" }}
              >
                <AddCircleOutlineIcon fontSize="small" /> New Ticket
              </button> */}
            </div>
            <div
              className="form-group mb-2 mb-md-0 filter-btn"
              style={{ width: "4.9rem" }}
            >
              <select
                className="form-select"
                id="sortSelect"
                onChange={handleSortChange}
                data-toggle="tooltip"
                data-placement="top"
                title="Filter by Status"
                value={sortValue}
              >
                <option value="All">All</option>
                <option value="Assigned">Assigned</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Closed">Closed</option>
                <option value="New">New</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
                <option value="Resolved">Resolved</option>
                <option value="Duplicate">Duplicate</option>
              </select>
            </div>
      
          </div>
        </div>
      </div>
      <div className="table mb-4">
        {!loading && (totalTickets === 0 || filteredTickets?.length === 0) ? (
          <div className="text-center">No tickets available</div>
        ) : (
          <>
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr>
                  <th scope="col">S NO</th>
                  <th scope="col">Case Number</th>
                  <th scope="col">Case Title</th>
                  <th scope="col">Ticket Type</th>
                  <th scope="col">Raised On</th>
                  <th scope="col">Assigned To</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {paginateTickets().map((ticket, index) => {
                  
                  
                  const ticketNumber = ticket.ticket_number ? ticket.ticket_number : ticket.case_number ;
                  const ticketType = ticket.type;
                  const statusParts = ticket.status?.split("_");
                  const mainStatus = statusParts ? statusParts[1] : "";
                  const statusColor =
                    statusColors[mainStatus] || statusColors.Default;
                    

                  return (
                    <tr key={index}>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>
                        <a
                          id="user-button"
                          onClick={() =>
                            dispatch(showTicketDetailsPopup(ticket))
                          }
                        >
                          {ticketNumber}
                        </a>
                      </td>
                      <td>{ticket.name}</td>
                      <td>{ticketType}</td>
                      <td>{ticket.date_entered?.split(" ")[0]}</td>
                      <td>{ticket.assigned_user_name || "-"}</td>
                      <td>
                            <span>
                            <span style={{ background:statusColor }} class="dot"></span>
                      
                            <span>{mainStatus}</span>
                            </span>

                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
            <div id="loadingSpinner" style={{ display: "none" }}>
              <i className="fa-solid fa-spinner fa-3x mx-auto d-block"></i>
            </div>
          </>
        )}
        {loading && (
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="Pagination">
        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3 mb-3 text-center"
          style={{ textWrap: "nowrap" }}
        >
          <span>Items per page</span>
          <select
            className="form-select table-item-select"
            value={pageSize}
            onChange={handlePageSizeChange}
            style={{ width: "auto" }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className={`accordion-button ${isOpen ? "" : "collapsed"}`}
              type="button"
              onClick={toggleAccordion}
              aria-expanded={isOpen}
              aria-controls="collapseOne"
            >
              Knowledge Base
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="container mt-4">
                <div className="row">
                  {dataBase && dataBase.length > 0 ? (
                    dataBase.map((item) => (
                      <div
                        className="col-12 col-md-4 mb-4 custom-col-width" 
                        key={item.id}
                        style={{
                          marginLeft: "2%",
                          border: "1px solid #ccc",
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="card"
                          title="Preview book"
                          onClick={() => {
                            showContent(item.name, item?.id);
                          }}
                          style={{ border: "none" }}
                        >
                          <div
                            className="card-body"
                            style={{ border: "none " }}
                          >
                            <h5 className="card-title">{item.name}</h5>
                            <p className="card-text">
                              Created At: {item.created_at?.split("T")[0]}
                            </p>
                            <p className="card-text">Slug: {item.slug}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12">
                      <p>No data available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAddTicket && <AddTicket />}
      {showTicketDetails && ticketDetails && (
        <TicketDetails ticketDetails={ticketDetails} />
      )}
    </div>
  );
};

export default SelfService;
